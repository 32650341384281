import React from "react"
import { Helmet } from "react-helmet"

import config from "../../../config.json"
import { WrappedBase } from "../../shared-components/Shared/WrappedBase"

import { HomepageVariant } from "./types"

interface Props {
  variant: HomepageVariant
}

export const Base: React.FC<Props> = ({ children, variant }) => (
  <WrappedBase
    metaTitle="Habito: mortgages made easier"
    metaDescription="We're the Treating Customers Fairly Champion of 2023 as voted for in the British Bank Awards. We're here to make mortgages easier - no paperwork, no stress and no fees."
    canonicalUrl="https://www.habito.com"
    noIndex={false}
    pageName="homepage"
    intercom={true}
    config={config}
    amplitudePageLoadCallback={logEvent => {
      logEvent({
        // BEFORE YOU KILL THIS: we need to send an event /after/
        // identifying the user, because Amplitude sends identifying
        // information with the /next/ event. This is fine unless
        // people bounce. SO, if you're going to kill this event,
        // please replace it with something to make sure that data
        // is sent.
        title: "homepage_variant_viewed",
        payload: {
          homepage_variant: variant,
        },
      })
    }}
  >
    <Helmet>
      <meta
        name="facebook-domain-verification"
        content="nz6i3luerz9w2hc82wn6m2hgj8t0j3"
      />
      {/* This is here for SEO purposes */}
      <script type="application/ld+json">{`
        {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Habito",
            "url": "https://www.habito.com",
            "logo": "https://s3.eu-west-1.amazonaws.com/assets.habito.com/marketing_assets/HabitoCardHeader_Plain.png",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+44 330 223 0996",
              "contactType": "Customer Service",
              "areaServed": "UK",
              "availableLanguage": "English"
            },
            "sameAs": [
              "https://www.facebook.com/heyhabito",
              "https://twitter.com/habito",
              "https://www.linkedin.com/company/habito-",
              "https://register.fca.org.uk/s/firm?id=001b0000019w13aAAA"
            ],
            "owns": {
              "@type": "Person",
              "name": "Ying Tan",
              "jobTitle": "CEO",
              "worksFor": {
                "@type": "Organization",
                "name": "Habito"
              },
              "sameAs": [
                "https://uk.linkedin.com/in/ying-tan-6a188312"
              ]
            },
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Moor Place, WeWork, 1 Fore St Ave",
              "addressLocality": "London",
              "addressRegion": "Greater London",
              "postalCode": "EC2Y 9DT",
              "addressCountry": "UK"
            },
            "description": "Habito is a leading online mortgage broker, helping you find the best mortgage deals and manage your mortgage effortlessly."
          }
        `}</script>
    </Helmet>

    {children}
  </WrappedBase>
)
