import React from "react"
import styled from "@emotion/styled"

import {
  borderRadii,
  colours,
  horizontal,
  iconSize,
  mixins,
  resets,
  vertical,
} from "design-kit"

import { registerButtonClick } from "../../../utils/amplitude"

const pauseIcon: React.ReactNode = (
  <svg
    aria-hidden
    width={iconSize.s}
    height={iconSize.s}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 19H10V5H6V19ZM14 5V19H18V5H14Z" fill={colours.offBlack} />
  </svg>
)

const playIcon: React.ReactNode = (
  <svg
    aria-hidden
    width={iconSize.s}
    height={iconSize.s}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 5V19L19 12L8 5Z" fill={colours.offBlack} />
  </svg>
)

const Button = styled.button`
  ${resets.button}
  background-color: rgba(255, 255, 255, 0.5);
  padding: ${vertical.xxs} ${horizontal.s};
  border-radius: ${borderRadii.l};
  z-index: 1;
  margin-bottom: ${vertical.l};

  :hover {
    cursor: pointer;
  }

  :focus {
    ${mixins.focused}
  }
`

const IconContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`

const VisuallyHiddenText = styled.span`
  ${mixins.invisible}
`

export const PausePlayButton: React.FC<{
  isPaused: boolean
  setIsPaused: (b: boolean) => void
}> = ({ isPaused, setIsPaused }) => {
  const icon = isPaused ? playIcon : pauseIcon
  const hiddenText = `${
    isPaused ? "Play" : "Pause"
  } background animation of the phantasmagorical flying key`

  return (
    <Button
      onClick={() => {
        setIsPaused(!isPaused)
        registerButtonClick("homepage")(
          `${isPaused ? "Play" : "Pause"} background video`,
          "hero"
        )
      }}
    >
      <IconContainer>
        {icon}
        <VisuallyHiddenText>{hiddenText}</VisuallyHiddenText>
      </IconContainer>
    </Button>
  )
}
