import React from "react"
import { css } from "@emotion/react"
import {
  breakpoints,
  horizontal,
  PrimaryLink,
  useHasMounted,
  vertical,
} from "design-kit"
import queryString from "query-string"

import { UserContext } from "../../../shared-components/Shared/UserProvider"
import { registerButtonClick } from "../../../utils/amplitude"

export const MainCTAs: React.FC = () => {
  const hasMounted = useHasMounted()

  const { utm_source } = queryString.parse(
    typeof location !== "undefined" ? location.search : ""
  )

  const isFromMoneySavingExpert = utm_source === "moneysavingexpert"

  return (
    <UserContext.Consumer>
      {userEmail =>
        userEmail ? (
          <PrimaryLink
            text="Continue to the dashboard"
            href="/my-properties"
            width={{
              mobile: "full-width",
              tablet: "content-width",
              desktop: "content-width",
            }}
            onClick={() => {
              registerButtonClick("homepage")(
                "Continue to the dashboard",
                "hero"
              )
            }}
          />
        ) : (
          /**
           * `location` is not available during SSR, so we need to trigger a
           * re-render in the client
           */
          <React.Fragment key={`${hasMounted}`}>
            <PrimaryLink
              text="I want to buy a home"
              href={
                isFromMoneySavingExpert
                  ? "/sign-up"
                  : "/how-can-we-help/new-mortgage"
              }
              width={{
                mobile: "full-width",
                tablet: "content-width",
                desktop: "content-width",
              }}
              onClick={() => {
                registerButtonClick("homepage")("I want to buy a home")
              }}
            />

            <PrimaryLink
              css={css`
                margin-top: ${vertical.xs};

                ${breakpoints.tablet`
                  margin-top: 0;
                  margin-left: ${horizontal.s};
                `}
              `}
              text="I want to remortgage"
              href={
                isFromMoneySavingExpert
                  ? "/sign-up"
                  : "/how-can-we-help/remortgage"
              }
              width={{
                mobile: "full-width",
                tablet: "content-width",
                desktop: "content-width",
              }}
              onClick={() => {
                registerButtonClick("homepage")("I want to remortgage")
              }}
            />
          </React.Fragment>
        )
      }
    </UserContext.Consumer>
  )
}
